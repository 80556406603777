import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { globalHistory } from '@reach/router';
import theme from './theme';
import { parseLanguage } from './gatsby/utils';

const LocaleContext = createContext();
const { pathname } = globalHistory.location;

class App extends Component {
  state = {
    locale: '',
    flag: ''
  };

  componentDidMount() {
    const languageFromUrl = pathname.substring(1, 3);
    const { locale, flag } = parseLanguage(languageFromUrl);
    this.changeLocale(locale, flag);
  }

  changeLocale = (locale, flag) => {
    this.setState({ locale, flag });
  };

  render() {
    const { children } = this.props;
    const { locale, flag } = this.state;
    const localeContext = {
      locale,
      flag,
      changeLocale: this.changeLocale
    };

    return (
      <React.Fragment>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <MuiThemeProvider theme={theme}>
          <LocaleContext.Provider value={localeContext}>
            <CssBaseline />
            {children}
          </LocaleContext.Provider>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

App.propTypes = {
  children: PropTypes.node.isRequired
};

export { LocaleContext };

export default App;
