// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-js": () => import("/builds/mkit/clients/c-web-primavet/src/templates/Article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-about-js": () => import("/builds/mkit/clients/c-web-primavet/src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-product-js": () => import("/builds/mkit/clients/c-web-primavet/src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-pages-404-js": () => import("/builds/mkit/clients/c-web-primavet/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-js": () => import("/builds/mkit/clients/c-web-primavet/src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-distributors-js": () => import("/builds/mkit/clients/c-web-primavet/src/pages/distributors.js" /* webpackChunkName: "component---src-pages-distributors-js" */),
  "component---src-pages-index-js": () => import("/builds/mkit/clients/c-web-primavet/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/builds/mkit/clients/c-web-primavet/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-publications-js": () => import("/builds/mkit/clients/c-web-primavet/src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */)
}

