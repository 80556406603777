import { blue, deepPurple } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: deepPurple
  },
  background: {
    default: '#fff'
  }
});

export default theme;
