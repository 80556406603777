const locales = require('./locales');

// Remove trailing slashes unless it's only "/", then leave it as it is
exports.replaceTrailing = path =>
  path === `/` ? path : path.replace(/\/$/, ``);

// If the "lang" is the default language, don't create a prefix. Otherwise add a "/$locale/" before the path/:slug
exports.localizedSlug = (lang, path) =>
  locales[lang].default ? path : `/${locales[lang].path}${path}`;

exports.parseLanguage = lang => {
  switch (lang) {
    case 'en':
      return {
        locale: 'en-us',
        flag: 'gb'
      };
    case 'ru':
      return {
        locale: 'ru',
        flag: 'ru'
      };
    default:
      return {
        locale: 'bg',
        flag: 'bg'
      };
  }
};

exports.parseLinkForFlagButton = (locale, lang, pathname) => {
  switch (locale) {
    case 'bg':
      return lang === 'bg' ? `${pathname}` : `/${lang}${pathname}`;
    default:
      return lang === 'bg'
        ? `${pathname.substring(3)}`
        : `/${lang}${pathname.substring(3)}`;
  }
};
