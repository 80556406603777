module.exports = {
  bg: {
    default: true,
    path: 'bg',
    locale: 'bg',
    flag: 'bg',
    language: 'Български'
  },
  'en-us': {
    path: 'en',
    locale: 'en-us',
    flag: 'gb',
    language: 'English'
  },
  ru: {
    path: 'ru',
    locale: 'ru',
    flag: 'ru',
    language: 'Русский'
  }
};
